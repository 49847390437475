@value colorWhite from '~Styles/vars/colors.css';

.root {
  border-radius: 8px;
  background: colorWhite;
  border: 1px solid #d5d8d9;
  padding: 28px 16px;
  size: 385px 107px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .subtitle {
    color: #505555;
  }

  .tag {
    color: #017989;
    background: #e6fcff;
    padding: 2.5px 8px;
  }
}

.subtitleWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tags {
  display: flex;
  gap: 4px;
  position: absolute;
  top: 8px;
  right: 16px;
}
