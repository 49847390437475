@value colorGrey100 from '~Styles/vars/colors.css';

.listItem {
  align-items: center;
  width: 100%;

  .listItemEl {
    padding: 7px 14px;
    box-sizing: border-box;
    width: 100%;

    .listItemText {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 356px;
    }
  }
}

.checkbox {
  size: 24px;
  padding: 4px;
  @mixin center;
  background: url('~files/images/icons/checkbox-off.svg') 0 0 no-repeat;
  background-position: center;
}

.emptyList {
  color: colorGrey100;
  padding: 16px 0;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.isActive {
  .checkbox {
    background: url('~files/images/icons/checkbox-on.svg') 0 0 no-repeat;
    background-position: center;
  }
}
